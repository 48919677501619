html {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "PlayWriteUSAModern";
  src: local("PlayWriteUSAModern"),
  url("./fonts/PlaywriteUSAModern/PlaywriteUSModern-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "UbuntuSans-Regular";
  src: local("UbuntuSans-Regular"),
  url("./fonts/UbuntuSans/UbuntuSans-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "UbuntuSans-Medium";
  src: local("UbuntuSans-Medium"),
  url("./fonts/UbuntuSans/UbuntuSans-Medium.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "UbuntuSans-SemiBold";
  src: local("UbuntuSans-SemiBold"),
  url("./fonts/UbuntuSans/UbuntuSans-SemiBold.ttf") format("truetype");
  font-weight: normal;
}

